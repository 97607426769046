import type { NextPage } from 'next';

import { NavigationBar } from '@/components/navigation-bar';

import { Box } from '@/components/base/box';
import { HomeMenu } from '@/components/home/HomeMenu';
import { useContext, useEffect } from 'react';
import { useScoreCardEvent } from '@/hooks/use-score-card-event';
import { useAuthenticatedAxios } from '@/context/api-auth';
import { logger } from '@/services/logging';
import { ModalContext } from '@/context/modal';
import { useMealPlanCalendarStore } from '@/store/meal-plan-calendar-store';

const Home: NextPage = () => {
  const { sendScoreCardEvent } = useScoreCardEvent();
  const axiosCtx = useAuthenticatedAxios();

  useEffect(() => {
    (async () => {
      await sendScoreCardEvent('VIEW_MEAL_PLANNER');
    })();
  }, []);

  return (
    <>
      <Box backgroundColor="pageSecondaryBackgroundColor">
        <NavigationBar position={'initial'} />
      </Box>

      <HomeMenu />
    </>
  );
};

export default Home;
